import React, {FC} from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/Common/bio"
import Layout from "../components/Common/layout"
import SEO from "../components/Common/seo"

const BlogPostTemplate: FC<{ data: any, location: any }> = (props) => {
  const post = props.data.markdownRemark
  const siteTitle = props.data.site.siteMetadata?.title || `Blog - Cole Striler`
  const { previous, next } = props.data
  const { siteUrl } = props.data.site.siteMetadata
  const thumbnail = post.frontmatter.thumbnail

    var style = {
        blogSection : {
            "margin-bottom": 40,
        },
        date : {
            "margin-bottom": 0
        }

    }

  return (
    <Layout title={siteTitle} maxWidth="md">
      <SEO
        title={post.frontmatter.title}
        description={post.excerpt || post.frontmatter.description}
        published_time={post.frontmatter.date}
        thumbnail={thumbnail}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
          <Bio />
        <header>
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <p style={style.date}>{post.frontmatter.date}</p>
        </header>

        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
          style={style.blogSection}
        />
        <hr />

        <footer>
          <Bio showBio />
          {/*<SubstackForm />*/}
          {/*<Signup />*/}
        </footer>
      </article>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        thumbnail {
          childImageSharp {
            fixed(width: 1200) {
              ...GatsbyImageSharpFixed
            }
          }
        }

      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
